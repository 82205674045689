import jQuery from 'jquery';

(function ($) {
    $.fn.hiddenFilterOptions = function () {
        var $el = null;
        var $button = null;
        var $container = null;

        var init = function () {
            $button = $el.find('[data-element="hidden-options-button"]');
            $container = $el.find('[data-element="hidden-options-container"]');

            // console.log($el);
            // console.log($button);
            // console.log($container);

            $button.on('click', onClickButton);
        };

        var onClickButton = function(e) {
            e.preventDefault();

            if (!$container.hasClass('active')) {
                $container.css({ 'display' : 'block' });
                $container.addClass('active');
                $button.text('Pokaż mniej');
            } else {
                $container.css({ 'display' : 'none' });
                $container.removeClass('active');
                $button.text('Pokaż więcej');
            }

            // console.log($(this));
        };

        if (this.length > 0) {
            if (this.length > 1) {
                this.each(function () {
                    $(this).hiddenFilterOptions();
                })
            } else {
                $el = this;
                init();
            }
        }
    }
})(jQuery);