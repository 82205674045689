import jQuery from 'jquery';

(function($){
    $.fn.filterAttributes = function(){
        var $el = null;
        var $checkboxes = null;
        var $filter = null;

        var init = function(){
            $filter = $el.find('[data-element="filter"]');
            $checkboxes = $el.find('[data-element="checkbox"]');
            $checkboxes.on('change, click', function (e) {
                e.preventDefault();
                window.location.href = $(this).data('link');
            });

            // $checkboxes = $el.find('[data-element="checkbox"]');
            //
            // console.log($el);
            //
            // $checkboxes.on('change', function (e) {
            //     e.preventDefault();
                // var val = $filter.val();
                // val = val.split(',');
                // // console.log(val);
                // var index = val.indexOf($(this).val());
                // console.log(index);
                // if($(this).is(':checked')){
                //     if(index === -1) val.push($(this).val());
                // }
                // else {
                //     if(index > -1) val.splice(index, 1);
                // }
                // $filter.val(val.join(','));

                //V2
                // var str = $el.find('[data-element="checkbox"]:checked').map(function() {
                //     return $(this).val();
                // }).get().join(',');
                // $filter.val(str);
            // });
        };

        if(this.length > 0){
            if(this.length > 1){
                this.each(function(){
                    $(this).filterAttributes();
                })
            } else {
                $el = this;
                init();
            }
        }
    }

})(jQuery);