import jQuery from 'jquery';

(function ($) {
    $.fn.arrangementData = function () {
        var $el = null;
        var $content = null;
        var $button = null;

        var init = function () {
            $content = $el.find('[data-element="content"]');
            $button = $el.find('[data-element="button"]');

            $button.on('click', function(e) {
                e.preventDefault();

                if ($el.hasClass('arrangement-data__expand--shown')) {
                    $el.removeClass('arrangement-data__expand--shown');
                    $button.text('Rozwiń');
                } else {
                    $el.addClass('arrangement-data__expand--shown');
                    $button.text('Zwiń');
                }
            });
        };

        if (this.length > 0) {
            if (this.length > 1) {
                this.each(function () {
                    $(this).arrangementData();
                })
            } else {
                $el = this;
                init();
            }
        }
    }
})(jQuery);