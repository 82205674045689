import jQuery from 'jquery';

(function ($) {
    $.fn.accordion = function () {
        var $el = null;
        var $hidden_content = null;

        var init = function () {
            $el.on('click', '[data-link-element]', function(e) {
                e.preventDefault();
                $hidden_content = $(this).parent().find('[data-hidden-element]');
                if ($(this).parent().hasClass('open')) {
                    $hidden_content.slideUp('slow');
                    $(this).parent().removeClass('open');
                } else {
                    $hidden_content.slideDown('slow');
                    $(this).parent().addClass('open');
                }
            });

            if ($el.find('[data-single-element]').hasClass('open')) {
                $el.find('.open').find('[data-hidden-element]').slideDown('slow');
            }
        };

        if (this.length > 0) {
            if (this.length > 1) {
                this.each(function () {
                    $(this).accordion();
                })
            } else {
                $el = this;
                init();
            }
        }
    }
})(jQuery);