import jQuery from 'jquery';

(function ($) {
    $.fn.askTrader = function () {
        var $el = null;
        var $button = null;
        var $modal = null;
        var $close = null;

        var init = function () {
            $button = $el;

            if ($button !== null) {
                $button.on('click', buttonClick);
            }

            $modal = $('body').find('.js-trader-modal');
            if ($modal !== null) {
                $close = $modal.find('[data-element="close"]');
                if ($close !== null) {
                    $close.on('click', buttonClose);
                }
            }
        };

        var buttonClick = function(e) {
            e.preventDefault();

            if ($modal !== null) {
                if ($modal.hasClass('modal--ask-trader')) {
                    if (!$modal.hasClass('modal--shown')) {
                        $modal.addClass('modal--shown');
                        $('body').addClass('mask');
                    }
                }
            }
        };

        var buttonClose = function(e) {
            e.preventDefault();

            if ($modal.hasClass('modal--ask-trader')) {
                $modal.removeClass('modal--shown');
                $('body').removeClass('mask');
            }
        }
        if (this.length > 0) {
            if (this.length > 1) {
                this.each(function() {
                    $(this).askTrader();
                })
            } else {
                $el = this;
                init();
            }
        }
    }
})(jQuery);