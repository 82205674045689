import jQuery from 'jquery';
(function ($) {
    $.fn.shopSearch = function () {
        var $el = null,
            $input = null,
            $button = null,
            $products_button = null,

            $results = null,

            $result_products = null,
            $result_categories = null,
            $result_producents = null,
            $result_series = null,

            $loading = null,
            is_input_clicked = false,

            ajax_req_products = null,
            ajax_req_categories = null,
            ajax_req_producents = null,
            ajax_req_series = null,

            run = false;


        var init = function () {

            if ($(window).width() <= 768) {
                if ($el.hasClass('search-form--sticky')) {
                    $el.find('[data-element="results"]').css({ 'width' : 'calc(' + $(window).width() + 'px - 30px)' });
                }
            }

            $input = $el.find('[data-element="input"]');
            $button = $el.find('[data-element="button"]');
            $results = $el.find('[data-element="results"]');

            $products_button = $el.find('[data-element="products-button"]');

            $result_products = $results.find('[data-element="products"]');
            $result_categories = $results.find('[data-element="categories"]');
            $result_producents = $results.find('[data-element="producents"]');
            $result_series = $results.find('[data-element="series"]');

            $loading = $el.find('[data-element="loading"]');

            $input.on('focus click', function() {
                var search_string = $input.val();
                if (search_string.length >= 3) {
                    $loading.show();
                    run = true;
                    setTimeout(function() {
                        do_search(search_string);
                    }, 500);
                }
                else {
                    run = false;
                    $results.removeClass('search-results--shown');
                    clear_html();
                }
                is_input_clicked = true;
            });

            $input.on('keyup', delay(function() {
                var search_string = $input.val();
                if (search_string.length >= 3) {
                    $loading.show();
                    run = true;
                    setTimeout(function() {
                        do_search(search_string);
                    }, 500);
                }
                else {
                    run = false;
                    $results.removeClass('search-results--shown');
                    clear_html();
                }
                is_input_clicked = true;
            }, 500));


            $products_button.on('click', function(e) {
                e.preventDefault();

                var search_string = $input.val();
                if (search_string !== '')
                    window.location.href = '/?s=' + search_string + '&post_type=product';
            });

            $(document).on('click', function(e) {
                if ($(e.target) !== $input) {
                    $results.removeClass('search-results--shown');
                    $loading.hide();
                    is_input_clicked = false;

                    if (ajax_req_products !== null) ajax_req_products.abort();
                    if (ajax_req_categories !== null) ajax_req_categories.abort();
                    if (ajax_req_producents !== null) ajax_req_producents.abort();
                    if (ajax_req_series !== null) ajax_req_series.abort();
                }
            });
        };

        function do_search(string) {
            var step = 0;

            $.when(ajax_req_products = $.ajax({
                url: '/wp-admin/admin-ajax.php',
                type: "POST",
                data: {
                    action: 'nt_servitor_search_products',
                    product: string
                },
                success: function (response) {
                    var $list = $result_products.find('[data-element="list"]');
                    $list.empty();

                    if (response !== null && response !== '') {
                        $list.append(response);
                        if (!$result_products.is('visible')) $result_products.show();
                    } else {
                        $result_products.hide();
                    }
                },
                error: function () {
                    console.log('Wystąpił błąd podczas pobierania listy produktów');
                }
            })).then(function() {
                step++;
                console.log(step);

                check_step_count(step);
            });
            $.when(ajax_req_categories = $.ajax({
                url: '/wp-admin/admin-ajax.php',
                type: "POST",
                data: {
                    action: 'nt_servitor_search_categories',
                    category: string
                },
                success: function (response) {
                    var $menu = $result_categories.find('[data-element="menu"]');
                    $menu.empty();

                    if (response !== null && response !== '') {
                        $menu.append(response);
                        if (!$result_categories.is('visible')) $result_categories.show();
                    } else {
                        $result_categories.hide();
                    }
                },
                error: function () {
                    console.log('Wystąpił błąd podczas pobierania listy kategorii');
                }
            })).then(function() {
                step++;
                console.log(step);

                check_step_count(step);
            });
            $.when(ajax_req_producents = $.ajax({
                url: '/wp-admin/admin-ajax.php',
                type: "POST",
                data: {
                    action: 'nt_servitor_search_producents',
                    producent: string
                },
                success: function (response) {
                    var $menu = $result_producents.find('[data-element="menu"]');
                    $menu.empty();

                    if (response !== null && response !== '') {
                        $menu.append(response);
                        if (!$result_producents.is('visible')) $result_producents.show();
                    } else {
                        $result_producents.hide();
                    }
                },
                error: function () {
                    console.log('Wystąpił błąd podczas pobierania listy producentów');
                }
            })).then(function() {
                step++;
                console.log(step);

                check_step_count(step);
            });
            $.when(ajax_req_series = $.ajax({
                url: '/wp-admin/admin-ajax.php',
                type: "POST",
                data: {
                    action: 'nt_servitor_search_series',
                    series: string
                },
                success: function (response) {
                    var $menu = $result_series.find('[data-element="menu"]');
                    $menu.empty();

                    if (response !== null && response !== '') {
                        $menu.append(response);
                        if (!$result_series.is('visible')) $result_series.show();
                    } else {
                        $result_series.hide();
                    }
                },
                error: function () {
                    console.log('Wystąpił błąd podczas pobierania listy serii');
                }
            })).then(function() {
                step++;
                console.log(step);

                check_step_count(step);
            });
        }

        function check_step_count(count) {
            if (count === 4) {
                $loading.hide();
                if (run) $results.addClass('search-results--shown');
                else {
                    clear_html();
                }
            }
        }

        function clear_html() {
            $result_products.find('[data-element="list"]').empty();
            $result_categories.find('[data-element="menu"]').empty();
            $result_producents.find('[data-element="menu"]').empty();
            $result_series.find('[data-element="menu"]').empty();
        }

        function delay(callback, ms) {
            var timer = 0;
            return function() {
                var context = this, args = arguments;
                clearTimeout(timer);
                timer = setTimeout(function () {
                    callback.apply(context, args);
                }, ms || 0);
            };
        }

        if (this.length > 0) {
            if (this.length > 1) {
                this.each(function () {
                    $(this).shopSearch();
                })
            } else {
                $el = this;
                init();
            }
        }
    }
})(jQuery);