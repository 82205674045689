const setCookie = (cookie_name, cookie_value, days) => {
    let d = new Date();
    d.setTime(d.getTime() + (days*24*60*60*1000));
    let expires = '';
    if (typeof days !== 'undefined') expires = "expires=" + d.toUTCString() + ';';
    document.cookie = cookie_name + '=' + cookie_value + ';' + expires + 'path=/';
};

const getCookie = (cookie_name) => {
    let name = cookie_name + '=';
    let decodedCookie = decodeURIComponent(document.cookie);
    let ca = decodedCookie.split(';');
    for (let i = 0; i < ca.length; i++) {
        let c = ca[i];
        while (c.charAt(0) == ' ') {
            c = c.substring(1);
        }
        if (c.indexOf(name) == 0) {
            return c.substring(name.length, c.length);
        }
    }
    return '';
};

export const get = getCookie;
export const set = setCookie;