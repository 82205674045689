import jQuery from 'jquery';

(function ($) {
    $.fn.arrangementsProduct = function() {
        var $el = null;
        var $icon = null;
        var $productBox = null;
        var $slide = null;
        var $loader = null;
        var is_mobile = false;

        var init = function() {
            $icon = $el.find('.arrangement-product__link');
            $productBox = $el.find('.arrangement-box');
            $loader = $productBox.find('.arrangement-box__loading');
            $slide = $el.closest('.slide');

            var iconOffset = $el.offset();
            if ($(window).width() <= 768) is_mobile = true;
            //
            // if (!is_mobile) {
                if ((iconOffset.left + $el.width() + $productBox.width()) > $slide.width() && (iconOffset.top + $el.height() + $productBox.height()) > $slide.height()) {
                    $productBox.css({"top":"initial", "right":"100%", "bottom":"0", "left":"initial"});
                } else if ((iconOffset.left + $el.width() + $productBox.width()) > $slide.width()) {
                    $productBox.css({"top":"0", "right":"100%", "bottom":"initial", "left":"initial"});
                } else if ((iconOffset.top + $el.height() + $productBox.height()) > $slide.height()) {
                    $productBox.css({"top":"initial", "right":"initial", "bottom":"0", "left":"100%"});
                }

            $icon.on('mouseover', showProductBox);
            $el.on('mouseleave', hideProductBox);
            $el.on('click', toggleProductBox);

            if (is_mobile) {
                if ($el.closest('.js-slider') !== null) {
                    $el.bind('click', function(e) {
                        // console.log($(e.target));
                        e.preventDefault();

                        // console.log($(e.target), $el);

                        if ($(e.target) !== $el) {
                            removeVisible();
                        }
                    });
                } else {
                    $el.parent().bind('click', function(e) {
                        // console.log($(e.target));
                        e.preventDefault();

                        // console.log($(e.target), $el);

                        if ($(e.target) !== $el) {
                            removeVisible();
                        }
                    });
                }
            }
            ajaxCall($productBox.parent().attr('data-id'));
        };

        var showProductBox = function() {
            $productBox.css({'opacity': 1, 'visibility': 'visible', 'box-shadow': '0 10px 20px rgba(0,0,0,0.19), 0 6px 6px rgba(0,0,0,0.23)'});
            $el.css({ 'z-index' : '5' });
            $productBox.show();
        };

        var hideProductBox = function() {
            $productBox.css({'opacity': 0, 'visibility': 'hidden', 'box-shadow': 'none'});
            $el.css({ 'z-index' : '4' });
            $productBox.hide();
        };

        var toggleProductBox = function(e) {
            if (is_mobile) {
                // e.preventDefault();
                if ($productBox.hasClass('visible')) {
                    $productBox.removeClass('visible');
                    $productBox.css({'opacity': 0, 'visiblility': 'hidden', 'box-shadow': 'none'});
                    $el.css({ 'z-index' : '4' });
                    $productBox.hide();
                } else {
                    removeVisible();
                    $productBox.addClass('visible');
                    if ($(window).width() <= 550) {
                        var dpfl = $el.offset().left;
                        $productBox.css({'opacity': 1, 'visibility': 'visible', 'box-shadow': '0 10px 20px rgba(0,0,0,0.19), 0 6px 6px rgba(0,0,0,0.23)', 'top' : '100%', 'left' : '-' + (dpfl - 20) + 'px',
                            'min-width' : '0', 'width' : 'calc(100vw - 40px)' });
                    } else {
                        $productBox.css({'opacity': 1, 'visibility': 'visible', 'box-shadow': '0 10px 20px rgba(0,0,0,0.19), 0 6px 6px rgba(0,0,0,0.23)'});
                    }
                    $el.css({ 'z-index' : '5' });
                    $productBox.show();
                }
            }
        };

        var removeVisible = function() {
            $(document).find('.js-arrangement-product').find('.arrangement-box.visible').removeClass('visible');
        };

        var ajaxCall = function(id) {
            $.ajax({
                url : '/wp-admin/admin-ajax.php',
                type : 'post',
                data : {
                    id: id,
                    action : 'nt_slider'
                },
                success : function(response) {
                    $loader.remove();
                    if (response !== 'Produkt nie istnieje') {
                        $productBox.append(response);
                        $productBox.parent().css({'display':'block'});
                    }
                },
                error : function() {
                    $loader.html('<div class="loader__error">coś poszło nie tak</div>');
                }
            });
        };

        if (this.length > 0) {
            if (this.length > 1) {
                this.each(function() {
                    $(this).arrangementsProduct();
                });
            } else {
                $el = this;
                init();
            }
        }
    };
})(jQuery);

(function ($) {
    $.fn.arrangementsDynamicProduct = function() {
        var $el = null;
        var $icon = null;
        var $productBox = null;
        var $slide = null;
        var $loader = null;
        var $popup = null;

        var init = function() {
            $icon = $el.find('.arrangement-product__link');
            $productBox = $el.find('.arrangement-box');
            $loader = $productBox.find('.arrangement-box__loading');
            $slide = $el.closest('.section--paper');
            $popup = $el.closest('.section--paper').find('[data-element="popup"]');

            $icon.on('mouseover', showProductBox);
            $el.on('mouseleave', hideProductBox);
            $popup.on('mouseleave', hideProductBox);
        };

        var getOffset = function(element) {
            var rect = element.getBoundingClientRect();
            return {
                left: rect.left + window.scrollX, top: rect.top + window.scrollY
            }
        };

        var showProductBox = function(e) {
            e.preventDefault();

            $popup.css({ 'position' : 'absolute', 'z-index' : 1000 });

            var iconOffset = $(this).offset();
            var parentOffset = $(this).closest('section').offset();
            var pointOffset = {
                top: iconOffset.top - parentOffset.top,
                left: iconOffset.left,
                width: $(this).width(),
                height: $(this).height()
            };

            var bounds = {
                top: 'initial',
                left: 'initial',
                right: 'initial',
                bottom: 'initial'
            };

            if ((pointOffset.left + pointOffset.width + 480) > $slide.width()) {
                bounds.right = ($slide.width() - pointOffset.left);
            } else {
                bounds.left = pointOffset.left;
            }
            if ((pointOffset.top + pointOffset.height + 220) > $slide.height()) {
                bounds.bottom = ($slide.height() - pointOffset.top);
            } else {
                bounds.top = pointOffset.top;
            }

            ajaxCall($(this).parent().parent().attr('data-id'));

            $popup.css({'opacity': 1, 'visibility': 'visible', 'box-shadow': '0 10px 20px rgba(0,0,0,1), 0 6px 6px rgba(0,0,0,1)', 'left': bounds.left + 'px', 'top': bounds.top + 'px', 'right': bounds.right + 'px', 'bottom': bounds.bottom + 'px' });
            $popup.show();
        };

        var hideProductBox = function(e) {
            if (!$popup.has($(e.relatedTarget)).length && $(e.relatedTarget) != $popup) {
                $popup.css({"top":"initial", "right":"initial", "bottom": 'initial', "left": 'initial' });
                $popup.css({'opacity': 0, 'box-shadow': 'none', 'visibility': 'hidden' });
                $popup.hide();
                $popup.find('.arrangement-box').empty();
            }
        };

        var ajaxCall = function(id) {
            $loader.show();
            $loader.find('.loader').show();
            $.ajax({
                url : '/wp-admin/admin-ajax.php',
                type : 'post',
                data : {
                    id: id,
                    box: 'yes',
                    action : 'nt_slider'
                },
                success : function(response) {
                    $loader.remove();
                    $popup.html('');
                    $popup.append(response);
                },
                error : function() {
                    $loader.html('<div class="loader__error">coś poszło nie tak</div>');
                }
            });
        };

        if (this.length > 0) {
            if (this.length > 1) {
                this.each(function() {
                    $(this).arrangementsDynamicProduct();
                });
            } else {
                $el = this;
                init();
            }
        }
    };
})(jQuery);