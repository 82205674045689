import jQuery from 'jquery';
import './owlCarousel';

(function ($) {
    $.fn.asyncCarousel = function () {
        var $el = null;
        var carousel_type = null;
        var action = null;
        var contentLoaded = false;
        var product_id = null;
        var product_attribute = null;
        var $loader = null;

        var init = function () {
            carousel_type = $el.attr('data-carousel-type');
            action = $el.attr('data-action');
            $loader = $el.parent().find('[data-element="loader"]');

            // console.log('Async Carousel init');

            if (action === 'asyncLoad') {
                $(document).ready(function () {
                    ajaxRequest(carousel_type);
                });
            }
        };

        var ajaxRequest = function(type, product_id, product_attribute) {
            if (!$loader.hasClass('shown')) {
                $loader.addClass('shown');
            }
            if (!contentLoaded) {
                $.ajax({
                    url: '/wp-admin/admin-ajax.php',
                    type: "POST",
                    data: {
                        action: 'nt_servitor_async_carousel_products',
                        type: type,
                        pid: product_id,
                        pattr: product_attribute
                    },
                    success: function (response) {
                        if (response) {
                            var max_slides = 3;
                            var width = $(window).width();
                            if (width > 0 && width <= 659) {
                                max_slides = 1;
                            } else if (width >= 660 && width <= 990) {
                                max_slides = 2;
                            } else max_slides = 3;

                            $el.append(response);
                            if ($el.find('.product').length > max_slides) {
                                if (carousel_type === 'promotions') {
                                    $el.addClass('js-promotion-carousel');
                                    $el.owlCarousel({
                                        loop: true,
                                        margin: 10,
                                        nav: true,
                                        dots: false,
                                        slideBy: 4,
                                        navText: ['<div class="slider__arrow slider__arrow--left"></div>', '<div class="slider__arrow slider__arrow--right"></div>'],
                                        responsive: {
                                            0: {
                                                items: 1
                                            },
                                            550: {
                                                items: 2
                                            },
                                            991: {
                                                items: 3
                                            },
                                            1199: {
                                                items: 3
                                            },
                                            1336: {
                                                items: 4
                                            }
                                        }
                                    });
                                } else if (carousel_type === 'bestsellers') {
                                    $el.addClass('js-bestseller-carousel');
                                    $el.owlCarousel({
                                        loop: true,
                                        margin: 10,
                                        nav: true,
                                        dots: false,
                                        slideBy: 4,
                                        navText: ['<div class="slider__arrow slider__arrow--left"></div>', '<div class="slider__arrow slider__arrow--right"></div>'],
                                        responsive: {
                                            0: {
                                                items: 1
                                            },
                                            991: {
                                                items: 2
                                            },
                                            1199: {
                                                items: 3
                                            },
                                            1336: {
                                                items: 4
                                            }
                                        }
                                    });
                                }
                            }
                            else {
                                $el.removeClass('owl-carousel').removeClass('owl-theme').addClass('product-carousel--static');
                            }
                            if ($loader.hasClass('shown')) {
                                $loader.removeClass('shown');
                            }
                            contentLoaded = true;
                        }
                    },
                    error: function () {

                    }
                });
            }
        };

        if (this.length > 0) {
            if (this.length > 1) {
                this.each(function () {
                    $(this).asyncCarousel();
                })
            } else {
                $el = this;
                init();
            }
        }
    }
})(jQuery);