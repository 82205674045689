import jQuery from 'jquery';

(function($){
    $.fn.productQty = function(){
        var $el = null;
        var $mInBox = null;

        var init = function () {
            $el.on('click', '[data-action="increase"]', increaseQty);
            $el.on('click', '[data-action="decrease"]', decreaseQty);

            $mInBox = $el.find('[data-element="meters-in-box"]');
            if ($el.find('[data-element="meters-in-box"]').length > 0) {
                updateMeters($el.find('[data-element="meters-in-box"]').attr('data-amount'), $el.find('[data-element="product-qty"]'));
            }

            $el.find('[data-element="product-qty"]').on('change', function() {
                updateMeters($(this).closest('.js-single-product-qty').find('[data-element="meters-in-box"]').attr('data-amount'), $(this).closest('[data-element="product-qty"]'));
            });
        };

        var increaseQty = function(e){
            e.preventDefault();
            updateInput('increase', $(this).parent().parent().find('[data-element="product-qty"]'));
            if ($(this).closest('.js-single-product-qty').find('[data-element="meters-in-box"]').length > 0) {
                updateMeters($(this).closest('.js-single-product-qty').find('[data-element="meters-in-box"]').attr('data-amount'), $(this).parent().parent().find('[data-element="product-qty"]'));
            }
        };
        var decreaseQty = function(e){
            e.preventDefault();
            updateInput('decrease', $(this).parent().parent().find('[data-element="product-qty"]'));
            if ($(this).closest('.js-single-product-qty').find('[data-element="meters-in-box"]').length > 0) {
                updateMeters($(this).closest('.js-single-product-qty').find('[data-element="meters-in-box"]').attr('data-amount'), $(this).parent().parent().find('[data-element="product-qty"]'));
            }
        };

        var updateInput = function(method, quantity) {
            var qty = quantity.val();
            var qty_min = parseInt(quantity.attr('min'));
            var qty_max = parseInt(quantity.attr('max'));

            switch (method) {
                case 'increase':  {
                    qty++;
                    if (typeof quantity.attr('max') !== 'undefined') {
                        if (qty > qty_max) qty = qty_max;
                    }
                    break;
                }
                case 'decrease': {
                    qty--;
                    if (typeof quantity.attr('min')  !== 'undefined') {
                        if (qty < qty_min) qty = qty_min;
                    }
                    break;
                }
            }
            var $update_cart_btn = $('body').find('button[name="update_cart"]');
            if ($update_cart_btn.attr('disabled') || $update_cart_btn.is('disabled')) {
                $update_cart_btn.prop('disabled', false);
            }
            quantity.val(qty);
        };

        var updateMeters = function(meters, quantity) {
            var qty = quantity.val();

            var tmp_qty = parseFloat(qty.replace(",", "."));
            var tmp_meters = parseFloat(meters.replace(",", "."));

            $mInBox.html((tmp_qty*tmp_meters).toFixed(2) + ' m<sup>2</sup>');
        };

        if (this.length > 0) {
            if (this.length > 1) {
                this.each(function() {
                    $(this).productQty();
                });
            } else {
                $el = this;
                init();
            }
        }
    };
})(jQuery);