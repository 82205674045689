import jQuery from 'jquery';
import * as cookie from './cookie';

(function ($) {
    $.fn.locationPicker = function () {
        var $el = null;
        var $options = null;
        var $icon = null;
        var $location_list = null;
        var $placeholder = null;

        var init = function () {
            $options = $el.find('ul');
            $icon = $el.find('.icon');
            $el.on('click', show);

            $location_list = $el.find('[data-element="list"]');
            $placeholder = $el.find('[data-element="placeholder"]');

            $location_list.on('click', 'a', location_list_click);
        };

        var show = function(e) {
            e.preventDefault();
            if (!$(this).hasClass('opened')) {
                var h = 0;
                $options.find('li').each(function() {
                    h += $(this).outerHeight(true);
                });
                $options.css({"bottom": "-" + h + "px"});
                $(this).addClass('opened');
                $icon.removeClass('icon--menu-down');
                $icon.addClass('icon--menu-up');
            } else {
                $options.css({"bottom": "0px"});
                $(this).removeClass('opened');
                $icon.removeClass('icon--menu-up');
                $icon.addClass('icon--menu-down');
            }
        };

        var location_list_click = function(e) {
            e.preventDefault();

            $placeholder.html('');
            $placeholder.html($(this).html());
            cookie.set('nt_location', $(this).attr('data-id'), 30);
            location.reload();
        };

        if (this.length > 0) {
            if (this.length > 1) {
                this.each(function() {
                    $(this).locationPicker();
                })
            } else {
                $el = this;
                init();
            }
        }
    }
})(jQuery);