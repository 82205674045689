import jQuery from 'jquery';

jQuery('.js-contact-form').customForm({
    fileUpload: {
        active: true,
        apiUrl: 'https://servitor.pl//upload.php',
    },
    onSubmit: function(event, $form) {
        event.preventDefault();

        var data = {
            first_name: '',
            last_name: '',
            person: '',
            phone: '',
            email: '',
            location: '',
            meeting_date: '',
            meeting_time: '',
            message: '',
            recaptcha: '',
            // subject: '',
            files: [],
            type: '',
            action: 'contact_form',
        };
        if ($form.find('[name="first_name"]').length > 0) data.first_name = $form.find('[name="first_name"]').val();
        if ($form.find('[name="last_name"]').length > 0) data.last_name = $form.find('[name="last_name"]').val();
        if ($form.find('[name="person"]').length > 0) data.person = $form.find('[name="person"]').val();
        if ($form.find('[name="phone"]').length > 0) data.phone = $form.find('[name="phone"]').val();
        if ($form.find('[name="email"]').length > 0) data.email = $form.find('[name="email"]').val();
        if ($form.find('[name="meeting_date"]').length > 0) data.meeting_date = $form.find('[name="meeting_date"]').val();
        if ($form.find('[name="meeting_time"]').length > 0) data.meeting_time = $form.find('[name="meeting_time"]').val();
        if ($form.find('[name="location"]').length > 0) data.location = $form.find('[name="location"]').val();
        // if ($form.find('[name="subject"]').length > 0) data.subject = $form.find('[name="subject"]').val();
        if ($form.find('[name="type"]').length > 0) data.type = $form.find('[name="type"]').val();
        if ($form.find('[name="message"]').length > 0) data.message = $form.find('[name="message"]').val();

        if ($form.find('[name="files[]"]').length > 0) {
            $form.find('[name="files[]"]').each(function() {
                data.files.push(jQuery(this).val());
            });
        }

        var $sending = $form.find('.form-send');
        $sending.fadeIn(300);
        $sending.find('.success').hide();
        $sending.find('.error').hide();
        var $loader = $form.find('.loader');
        $loader.fadeIn(300);

        if (window.grecaptcha) {
            grecaptcha.execute('6LfIwbIUAAAAAMDboOxaChKPGrbF0Ur_Gsznwf9r', { action: 'contact_form' }).then(function (token) {
                data.recaptcha = token;

                jQuery.ajax({
                    url: '/wp-admin/admin-ajax.php',
                    type: "POST",
                    data: data,
                    dataType: 'json',
                    success: function (response) {
                        $loader.fadeOut('slow');
                        if (response.status === 'success') {
                            $sending.find('.success').fadeIn(500);
                            setTimeout(function () {
                                $form.find('[name="first_name"]').val('');
                                $form.find('[name="last_name"]').val('');
                                $form.find('[name="person"]').val('');
                                $form.find('[name="phone"]').val('');
                                $form.find('[name="email"]').val('');
                                $form.find('[name="meeting_date"]').val('');
                                $form.find('[name="meeting_time"]').val('');
                                // $form.find('[name="subject"]').val('');
                                $form.find('[name="message"]').val('');
                                $form.find('[name="accept"]').prop('checked', false);

                                $sending.find('.success').hide();
                                $sending.fadeOut('slow');
                            }, 2000);
                        } else if (response.status === 'error') {
                            $sending.find('.error').find('span').text(response.message);
                            $sending.find('.error').fadeIn(500);
                        }
                    },
                    error: function () {
                        $loader.fadeOut('slow');
                        $sending.find('.error').fadeIn(500);
                        setTimeout(function () {
                            $sending.find('.error').hide();
                            $sending.fadeOut('slow');
                        }, 2000);
                    }
                });
            });
        } else {
            jQuery.ajax({
                url: '/wp-admin/admin-ajax.php',
                type: "POST",
                data: data,
                dataType: 'json',
                success: function (response) {
                    $loader.fadeOut('slow');
                    if (response.status === 'success') {
                        $sending.find('.success').fadeIn(500);
                        setTimeout(function () {
                            $form.find('[name="first_name"]').val('');
                            $form.find('[name="last_name"]').val('');
                            $form.find('[name="person"]').val('');
                            $form.find('[name="phone"]').val('');
                            $form.find('[name="email"]').val('');
                            $form.find('[name="meeting_date"]').val('');
                            $form.find('[name="meeting_time"]').val('');
                            // $form.find('[name="subject"]').val('');
                            $form.find('[name="message"]').val('');
                            $form.find('[name="accept"]').prop('checked', false);

                            $sending.find('.success').hide();
                            $sending.fadeOut('slow');
                        }, 2000);
                    } else if (response.status === 'error') {
                        $sending.find('.error').find('span').text(response.message);
                        $sending.find('.error').fadeIn(500);
                    }
                },
                error: function () {
                    $loader.fadeOut('slow');
                    $sending.find('.error').fadeIn(500);
                    setTimeout(function () {
                        $sending.find('.error').hide();
                        $sending.fadeOut('slow');
                    }, 2000);
                }
            });
        }
    }
});

jQuery('.js-ask-trader-form').customForm({
    onSubmit: function(event, $form) {
        event.preventDefault();

        var data = {
            name: '',
            email: '',
            amount: '',
            product: '',
            message: '',
            type: '',
            product_id: '',
            recaptcha: '',
            action: 'contact_trader',
        };
        if ($form.find('[name="name"]').length > 0) data.name = $form.find('[name="name"]').val();
        if ($form.find('[name="email"]').length > 0) data.email = $form.find('[name="email"]').val();
        if ($form.find('[name="amount"]').length > 0) data.amount = $form.find('[name="amount"]').val();
        if ($form.find('[name="product"]').length > 0) data.product = $form.find('[name="product"]').val();
        if ($form.find('[name="message"]').length > 0) data.message = $form.find('[name="message"]').val();
        if ($form.find('[name="type"]').length > 0) data.type = $form.find('[name="type"]').val();
        if ($form.find('[name="product-id"]').length > 0) data.product_id = $form.find('[name="product-id"]').val();

        var $sending = $form.find('.form-send');
        $sending.fadeIn(300);
        $sending.find('.success').hide();
        $sending.find('.error').hide();
        var $loader = $form.find('.loader');
        $loader.fadeIn(300);
        jQuery('body').addClass('mask');

        if (window.grecaptcha) {
            grecaptcha.execute('6LfIwbIUAAAAAMDboOxaChKPGrbF0Ur_Gsznwf9r', { action: 'contact_trader' }).then(function (token) {
                data.recaptcha = token;

                jQuery.ajax({
                    url: '/wp-admin/admin-ajax.php',
                    type: "POST",
                    data: data,
                    dataType: 'json',
                    success: function (response) {
                        $loader.fadeOut('slow');
                        if (response.status === 'success') {
                            $sending.find('.success').fadeIn(500);
                            setTimeout(function () {
                                $form.find('[name="name"]').val('');
                                $form.find('[name="email"]').val('');
                                $form.find('[name="amount"]').val('');
                                $form.find('[name="product"]').val('');
                                $form.find('[name="message"]').val('');

                                $sending.find('.success').hide();
                                $sending.fadeOut('slow');
                            }, 2000);
                        } else if (response.status === 'error') {
                            $sending.find('.error').find('span').text(response.message);
                            $sending.find('.error').fadeIn(500);
                        }
                    },
                    error: function () {
                        $loader.fadeOut('slow');
                        $sending.find('.error').fadeIn(500);
                        setTimeout(function () {
                            $sending.find('.error').hide();
                            $sending.fadeOut('slow');
                        }, 2000);
                    }
                });
            });
        } else {
            jQuery.ajax({
                url: '/wp-admin/admin-ajax.php',
                type: "POST",
                data: data,
                dataType: 'json',
                success: function (response) {
                    $loader.fadeOut('slow');
                    if (response.status === 'success') {
                        $sending.find('.success').fadeIn(500);
                        setTimeout(function () {
                            $form.find('[name="name"]').val('');
                            $form.find('[name="email"]').val('');
                            $form.find('[name="amount"]').val('');
                            $form.find('[name="product"]').val('');
                            $form.find('[name="message"]').val('');

                            $sending.find('.success').hide();
                            $sending.fadeOut('slow');
                        }, 2000);
                    } else if (response.status === 'error') {
                        $sending.find('.error').find('span').text(response.message);
                        $sending.find('.error').fadeIn(500);
                    }
                },
                error: function () {
                    $loader.fadeOut('slow');
                    $sending.find('.error').fadeIn(500);
                    setTimeout(function () {
                        $sending.find('.error').hide();
                        $sending.fadeOut('slow');
                    }, 2000);
                }
            });
        }
    }
});