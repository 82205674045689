import jQuery from 'jquery';

function scrollAnimDetection() {
    var
        ww = jQuery(window).width(),
        wh = jQuery(window).height(),
        wtp = jQuery(window).scrollTop() - 200,
        wbp = (wtp + wh),
        $animation_elements = null;

    $animation_elements = jQuery('body').find('.animation-element');
    jQuery.each($animation_elements, function() {
        var $el = jQuery(this);
        if (ww > 768) {
            var
                eh = $el.outerHeight(),
                etp = $el.offset().top,
                ebp = (etp + eh) - 500;

            if ((ebp >= wtp) && etp <= wbp){
                $el.addClass('visible');
                if ($el.hasClass('js-number-counter')) {
                    if (first_start === false) {
                        $el.find('.stats__number').countTo();
                        first_start = true;
                    }
                }
            }
        } else {
            $el.addClass('visible');
        }
    });
}
var first_start = false;
jQuery(window).on('scroll resize', scrollAnimDetection);
jQuery(window).trigger('scroll');