import jQuery from 'jquery';

(function ($) {
    $.fn.filterBox = function () {
        var $el = null;
        var $content = null;
        var $button = null;

        var init = function () {
            $content = $el.find('[data-element="content"]');
            $button = $el.find('[data-element="button"]');

            $button.on('click', on_button_click);

            if ($(window).width() <= 1000) {
                if ($content.hasClass('open')) {
                    $content.removeClass('open');
                    $content.toggle();
                    $button.text('(Rozwiń)');
                }
            }
        };

        var on_button_click = function(e) {
            e.preventDefault();

            if (!$content.hasClass('open')) {
                $content.addClass('open');
                $content.toggle();
                $button.text('(Zwiń)');
            } else {
                $content.removeClass('open');
                $content.toggle();
                $button.text('(Rozwiń)');
            }
        };

        if (this.length > 0) {
            if (this.length > 1) {
                this.each(function () {
                    $(this).filterBox();
                })
            } else {
                $el = this;
                init();
            }
        }
    }
})(jQuery);