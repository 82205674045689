import jQuery from 'jquery';

(function($){
    $.fn.stickySidebar = function(){
        var $el = null;
        var $parent = null;
        var box_width = 0;
        var box_height = 0;
        var last_scrolling_height = 0;
        var current_scrolling_height = 0;
        var max_range = 0;
        var $tmp_div = null;

        var init = function(){
            $parent = $el.parent();
            $tmp_div = $('<div></div>');
            box_width = $el.closest('.nt-single-product-page-sidebar').width();
            box_height = $el.outerHeight();

            $parent.prepend($tmp_div);

            if ($(window).width() > 767) {
                $(window).on('scroll resize', watch_scrolling);
            } else {
                $('body').css({ 'padding-bottom' : $el.outerHeight()+'px' });
            }
        };

        var watch_scrolling = function() {
            current_scrolling_height = $(window).scrollTop();

            max_range = $parent.offset().top + $parent.outerHeight();

            if (last_scrolling_height > current_scrolling_height) {
                if ((parseInt(current_scrolling_height)+parseInt($el.outerHeight())+parseInt(50) <= parseInt(max_range))) {
                    $el.removeClass('absolute');
                    $el.addClass('sticky-fixed');
                    $tmp_div.height(box_height);
                }
                if (current_scrolling_height < $parent.offset().top-45) {
                    $el.removeAttr('style');
                    $el.removeClass('sticky-fixed');
                    $tmp_div.height(0);
                }
            } else if (current_scrolling_height > last_scrolling_height) {
                if ((parseInt(current_scrolling_height)+parseInt($el.outerHeight())+parseInt(50) >= parseInt(max_range))) {
                    $el.removeClass('sticky-fixed');
                    $el.addClass('absolute');
                    $tmp_div.height(box_height);
                } else if (current_scrolling_height > $parent.offset().top-45) {
                    $el.attr('style', 'width: ' + box_width + 'px !important;');
                    $el.addClass('sticky-fixed');
                    $tmp_div.height(box_height);
                }
            }
            last_scrolling_height = current_scrolling_height;
        };

        if(this.length > 0){
            if(this.length > 1){
                this.each(function(){
                    $(this).stickySidebar();
                })
            } else {
                $el = this;
                init();
            }
        }
    }
})(jQuery);