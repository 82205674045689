import jQuery from 'jquery';

(function($){
    $.fn.productDescription = function(){
        var $el = null;
        var isMobile = false;
        var $clicker = null;
        var $content = null;
        var $span = null;

        var init = function(){
            if (window.innerWidth <= 767) {
                isMobile = true;
            }

            if (isMobile) {
                $clicker = $el.find('[data-element="clicker"]');
                $span = $el.find('[data-element="clicker"]').find('span');
                $content = $el.find('[data-element="content"]');

                if ($clicker !== null && $content !== null) {
                    $clicker.on('click', clickTitle);
                    $content.hide();
                }
            }
        };

        var clickTitle = function(e) {
              e.preventDefault();

              if (e.target.classList.contains('visible')) {
                  $clicker.removeClass('visible');
                  $content.hide();
                  $span.text('Rozwiń');
              } else {
                  $clicker.addClass('visible');
                  $content.show();
                  $span.text('Zwiń');
              }
        };

        if(this.length > 0){
            if(this.length > 1){
                this.each(function(){
                    $(this).productDescription();
                })
            } else {
                $el = this;
                init();
            }
        }
    }
})(jQuery);