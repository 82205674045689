import jQuery from 'jquery';

(function($){
    $.fn.mobileFilterNavigation = function(){
        var $el = null,
            $filter_button = null,
            $filter_widget = null,
            $filter_close_button = null;

        var init = function(){
            $filter_widget = $('body').find('.js-filter-box');

            $filter_button = $el.find('[data-element="button"]');

            $filter_close_button = $('<a></a>').attr('data-element', 'close').attr('href', '').addClass('box__button-close').text('×');
            $filter_widget.append($filter_close_button);

            $filter_button.on('click', function(e) {
                e.preventDefault();
                if ($(this).hasClass('shown')) {
                    $(this).removeClass('shown');
                    $filter_widget.removeClass('shown');
                    $('.js-sticky-navbar').css({ 'z-index' : 21 });
                    $('.header').css({ 'z-index' : 21 });

                    $('body').css('overflow', 'auto');
                    $('html').css('overflow', 'auto');
                } else {
                    $(this).addClass('shown');
                    $filter_widget.addClass('shown');
                    $('.js-sticky-navbar').css({ 'z-index' : 20 });
                    $('.header').css({ 'z-index' : 20 });

                    $('body').css('overflow', 'hidden');
                    $('html').css('overflow', 'hidden');
                }
            });

            $filter_close_button.on('click', function(e) {
                e.preventDefault();

                if ($filter_widget.hasClass('shown')) {
                    $filter_widget.removeClass('shown');
                    $filter_button.removeClass('shown');
                    $('.js-sticky-navbar').css({ 'z-index' : 21 });

                    $('body').css('overflow', 'auto');
                    $('html').css('overflow', 'auto');
                }
            });
            if ($(window).width() <= 991) {
                $('body').css({ 'padding-bottom' : $el.outerHeight()+'px' });
            }
        };

        if(this.length > 0){
            if(this.length > 1){
                this.each(function(){
                    $(this).mobileFilterNavigation();
                })
            } else {
                $el = this;
                init();
            }
        }
    }
})(jQuery);