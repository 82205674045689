/**
 * Created by Michał on 11.05.2017.
 */

import jQuery from 'jquery';
import jcf from '@netivo/customforms';
import lightbox from 'lightbox2/dist/js/lightbox';
import 'jquery-ui-dist/jquery-ui';
import './owlCarousel';
import PhotoSwipeLightbox from 'photoswipe/dist/umd/photoswipe-lightbox.umd.min';

import lp from './location-picker';
import ap from './arrangement-product';
import map from './map';
import * as cookie from './cookie';
import modal from './modal';
import ct from './category-tabs';
import f from './filters';
import apl from './arrangement-product-list';
import acc from './accordion';
import turn from './turn';
import forms from './forms';
import priceslider from './price-slider';
import stickySidebar from './stickySidebar';
import sad from './scrollAnimDetector';
import ts from './tag-select';
import product_qty from './product-qty';
import hiddenFilterOptions from './hiddenFilterOptions';
import filterAttributes from './filterAttributes';
import filterBox from './filterBox';
import asyncCarousel from './asyncCarousel';
import mobileFilterNavigation from './mobileFilterNavigation';
import askTrader from './askTrader';
import productDescription from './productDescription';
import * as shopSearch from './shopSearch';
import arrangementData from './arrangementData';

// import geolocation from './geolocation';

let images = document.querySelectorAll('img');
if (images !== null) {
    images.forEach(image => {
        if (!image.classList.contains('slide__image')) {
            image.setAttribute('loading', 'lazy');
        }
    });
}

function detectmob() {
    return ( navigator.userAgent.match(/Android/i)
        || navigator.userAgent.match(/webOS/i)
        || navigator.userAgent.match(/iPhone/i)
        || navigator.userAgent.match(/iPad/i)
        || navigator.userAgent.match(/iPod/i)
        || navigator.userAgent.match(/BlackBerry/i)
        || navigator.userAgent.match(/Windows Phone/i)
    );
}
function toggleMenu(){
    var menu = jQuery('#mobile-menu');
    var wrapper = jQuery('#wrapper');
    var content = jQuery('#content');
    jQuery('.js-tg-menu').toggleClass("active");
    jQuery('#mask').toggleClass('shown');
    menu.toggleClass("toggled");
    content.toggleClass("toggled");
    if(menu.hasClass("toggled")) {
        // wrapper.css('height', menu.height() + 'px');
        jQuery('body').css('overflow', 'hidden');
        jQuery('html').css('overflow', 'hidden');
    }
    else {
        // wrapper.css('height', 'auto');
        jQuery('body').css('overflow', 'auto');
        jQuery('html').css('overflow', 'auto');
    }
    //
}

jQuery(document).ready(function() {
    jQuery('.js-tg-menu').on('click', function (e) {
        e.preventDefault();
        toggleMenu();
    });
    jQuery('#mask').on('click', function (e) {
        toggleMenu();
    });

    jQuery('.js-async-carousel').asyncCarousel();
    jQuery('.js-ask-trader').askTrader();

    jQuery('.js-single-product-qty').productQty();
    jQuery('.js-location-picker').locationPicker();
    jQuery('.js-arrangement-product').arrangementsProduct();
    jQuery('.js-paper-product').arrangementsDynamicProduct();
    jQuery('.js-category-tabs').categoryTabs();
    jQuery('.js-checkbox-list').filters();
    jQuery('.js-arrangement-product-list').arrangementProductList();
    jQuery('.js-calculate-button').on('click', function(e) {
        e.preventDefault();
        if (jQuery('.js-arrangement-product-list-modal').length > 0) {
            jQuery('.js-arrangement-product-list-modal').nt_modal();
            jQuery('.js-arrangement-product-list-modal').css({ 'display' : 'block' });
        }
    });
    jQuery('.js-accordion').accordion();
    jQuery('.js-other-locations-cookies').on('click', 'a', function(){
        // e.preventDefault();
        cookie.set('nt_location', jQuery(this).attr('data-id'), 30);
    });
    jQuery('.js-sticky-sidebar').stickySidebar();
    jQuery('.js-product-description').productDescription();

    jQuery('.js-shop-search').shopSearch();
    if (jQuery(window).width() <= 991) {
        jQuery('.js-mobile-filter-navigation').mobileFilterNavigation();
    }

    // Paper
    if (jQuery(".js-paper").length > 0) {
        var turn_options = {};
        if (jQuery(window).width() <= 991) {
            turn_options = {
                width: (780/2),
                height: 600,
                autoCenter: true,
                acceleration: true,
                display: 'single',
                pages: 5,
                elevation: 5,
            }
        } else {
            turn_options = {
                width: 1000,
                height: 750,
                autoCenter: true,
                acceleration: true,
                display: 'double',
                pages: 5,
                elevation: 5,
            }
        }

        jQuery(".js-paper").turn(turn_options);
        var last_page = 0;
        jQuery('.js-paper-tooltip').find('[data-current-pages]').text(jQuery(".js-paper").turn('page'));
        jQuery('.js-paper').bind('turning', function(event, page, view) {
            last_page = jQuery(".js-paper").turn('page');
        });
        jQuery('.js-paper').bind('turned', function(event, page, view) {
            setTimeout(function() {
                jQuery(event.target).find('.js-paper-product').arrangementsDynamicProduct();
            }, 100);

            var result = '';
            if (page === 1) {
                result = page;
            } else if (last_page > jQuery(".js-paper").turn('page')) {
                result = (page-1) + ' - ' + page;
            } else if (last_page < jQuery(".js-paper").turn('page') && page !== jQuery('.js-paper').turn('pages')) {
                result = page + ' - ' + (page+1);
            } else {
                result = jQuery('.js-paper').turn('pages');
            }
            jQuery('.js-paper-tooltip').find('[data-current-pages]').text(result);
        });
        jQuery('.js-paper-tooltip').on('click', '[data-first-page]', function(e) {
            e.preventDefault();
            jQuery(".js-paper").turn('page', 1);
        });
        jQuery('.js-paper-tooltip').on('click', '[data-last-page]', function(e) {
            e.preventDefault();
            jQuery(".js-paper").turn('page', jQuery(".js-paper").turn('pages'));
        });
        jQuery('.js-paper-tooltip').on('click', '[data-next-page]', function(e) {
            e.preventDefault();
            jQuery(".js-paper").turn('next');
        });
        jQuery('.js-paper-tooltip').on('click', '[data-prev-page]', function(e) {
            e.preventDefault();
            jQuery(".js-paper").turn('previous');
        });
        jQuery('.js-paper-tooltip').on('click', '[data-zoom]', function(e) {
            e.preventDefault();

            if (jQuery(this).hasClass('active')) {
                jQuery('.js-paper').turn('zoom', 1.0);
                jQuery('.js-paper').turn('resize');
                jQuery(this).removeClass('active');
            }
            else {
                jQuery('.js-paper').turn('zoom', 1.5);
                jQuery('.js-paper').turn('resize');
                jQuery(this).addClass('active');
            }
        });
        jQuery('.js-paper-tooltip').on('click', '[data-fullscreen]', function(e) {
            e.preventDefault();

            var pageWidth = jQuery(window).width();
            var pageHeight = jQuery(window).height();
            var toolboxHeight = jQuery('.js-paper-tooltip').innerHeight();

            var scale = 0;
            if (jQuery(window).width() <= 991) {
                scale = ((780/2)/750);
            } else {
                scale = (1000/750);
            }

            var new_width = ((pageHeight-(2*toolboxHeight))*scale);
            var new_height = (pageHeight-(2*toolboxHeight+10));

            if (jQuery(this).hasClass('active')) {
                // jQuery('.js-paper').turn('zoom', 1);
                jQuery(this).removeClass('active');
                jQuery(this).find('span').removeClass('dashicons-editor-contract').addClass('dashicons-editor-expand');
                jQuery(this).closest('.section--paper').removeClass('section--fullscreen');
                if (jQuery(window).width() <= 991) {
                    jQuery(this).closest('.section--paper').find('.js-paper').turn('size', (780/2), 600).turn('resize');
                } else {
                    jQuery(this).closest('.section--paper').find('.js-paper').turn('size', 1000, 750).turn('resize');
                }
            }
            else {
                // jQuery('.js-paper').turn('zoom', 1.5);
                jQuery(this).addClass('active');
                jQuery(this).find('span').removeClass('dashicons-editor-expand').addClass('dashicons-editor-contract');
                jQuery(this).closest('.section--paper').addClass('section--fullscreen');
                jQuery(this).closest('.section--paper').find('.js-paper').turn('size', new_width, new_height).turn('resize');
            }
        });
    }

    // jQuery('.js-slider').slick({
    //     infinite: true,
    //     arrows: true,
    //     autoplay: true,
    //     adaptiveHeight: false,
    //     pauseOnHover: true,
    //     slidesToShow: 1,
    //     slidesToScroll: 1,
    //     slide: '.slide',
    //     rows: 0,
    //     dots: true,
    //     fade: true,
    //     cssEase: 'linear'
    // });

    var slider = jQuery('.js-slider');
    if (slider !== null) {
        slider.owlCarousel({
            lazyLoad: true,
            items: 1,
            nav: false,
            dots: true,
            autoplay: false, // true
            autoheight: false,
            loop: true,
            autoplayHoverPause: true,
            autoplayTimeout: 7000,
            smartSpeed: 500,
            slideBy: 1
        });
    }

    var tutorialCarousel = jQuery('.js-tutorial-carousel');
    if (tutorialCarousel !== null) {
        tutorialCarousel.owlCarousel({
            lazyLoad: true,
            items: 3,
            nav: true,
            dots: false,
            autoplay: true,
            // autoHeight: true,
            loop: true,
            margin: 10,
            autoplayHoverPause: true,
            autoplayTimeout: 7000,
            smartSpeed: 500,
            slideBy: 1,
            navText: ['<div class="slider__arrow slider__arrow--left"></div>', '<div class="slider__arrow slider__arrow--right"></div>'],
            responsive: {
                0: {
                    items: 1
                },
                991: {
                    items: 2
                },
                1199: {
                    items: 3
                }
            }
        });
    }

    // jQuery('.js-tutorial-carousel').slick({
    //     infinite: true,
    //     arrows: true,
    //     autoplay: false,
    //     adaptiveHeight: true,
    //     pauseOnHover: true,
    //     slidesToShow: 3,
    //     slidesToScroll: 1,
    //     slide: '.single-tutorial',
    //     rows: 0,
    //     nextArrow: '<div class="slider__arrow slider__arrow--right"></div>',
    //     prevArrow: '<div class="slider__arrow slider__arrow--left"></div>',
    //     responsive: [
    //         {
    //             breakpoint: 991,
    //             settings: {
    //                 slidesToShow: 2,
    //                 slidesToScroll: 2,
    //             }
    //         },
    //         {
    //             breakpoint: 550,
    //             settings: {
    //                 slidesToShow: 1,
    //                 slidesToScroll: 1,
    //             }
    //         }
    //     ]
    // });

    var bestsellerCarousel = jQuery('.js-bestseller-carousel');
    if (bestsellerCarousel !== null) {
        bestsellerCarousel.owlCarousel({
            lazyLoad: true,
            margin: 10,
            nav:true,
            dots: false,
            autoHeight: true,
            slideBy: 4,
            autoplay: false,
            autoplayHoverPause: true,
            navText: ['<div class="slider__arrow slider__arrow--left"></div>', '<div class="slider__arrow slider__arrow--right"></div>'],
            responsive: {
                0: {
                    items: 1,
                    slideBy: 1
                },
                660: {
                    items: 2,
                    slideBy: 2,
                },
                991: {
                    items: 3,
                    slideBy: 3
                },
                1199: {
                    items: 4,
                    slideBy: 4
                }
            }
        });
    }

    // jQuery('.js-bestseller-carousel').slick({
    //     infinite: true,
    //     arrows: true,
    //     autoplay: false,
    //     adaptiveHeight: true,
    //     pauseOnHover: true,
    //     slidesToShow: 4,
    //     slidesToScroll: 4,
    //     slide: '.product',
    //     rows: 0,
    //     nextArrow: '<div class="slider__arrow slider__arrow--right"></div>',
    //     prevArrow: '<div class="slider__arrow slider__arrow--left"></div>',
    //     responsive: [
    //         {
    //             breakpoint: 1199,
    //             settings: {
    //                 slidesToShow: 3,
    //                 slidesToScroll: 3,
    //             }
    //         },
    //         {
    //             breakpoint: 991,
    //             settings: {
    //                 slidesToShow: 2,
    //                 slidesToScroll: 2,
    //             }
    //         },
    //         {
    //             breakpoint: 660,
    //             settings: {
    //                 slidesToShow: 1,
    //                 slidesToScroll: 1,
    //             }
    //         }
    //     ]
    // });

    var producentCarousel = jQuery('.js-producent-slider');
    if (producentCarousel !== null) {
        producentCarousel.owlCarousel({
            lazyLoad: true,
            loop:true,
            margin: 10,
            nav:true,
            dots: false,
            slideBy: 5,
            autoHeight: true,
            autoplay: false,
            autoplayHoverPause: true,
            navText: ['<div class="slider__arrow slider__arrow--left"></div>', '<div class="slider__arrow slider__arrow--right"></div>'],
            responsive: {
                0: {
                    items: 1,
                    slideBy: 1
                },
                450: {
                    items: 2,
                    slideBy: 2,
                },
                660: {
                    items: 3,
                    slideBy: 3
                },
                991: {
                    items: 4,
                    slideBy: 4
                },
                1199: {
                    items: 5,
                    slideBy: 5
                }
            }
        });
    }

    // jQuery('.js-producent-slider').slick({
    //     infinite: true,
    //     arrows: true,
    //     autoplay: false,
    //     adaptiveHeight: true,
    //     pauseOnHover: true,
    //     slidesToShow: 5,
    //     slidesToScroll: 5,
    //     slide: '.producent-slider__single',
    //     rows: 0,
    //     nextArrow: '<div class="slider__arrow slider__arrow--right"></div>',
    //     prevArrow: '<div class="slider__arrow slider__arrow--left"></div>',
    //     responsive: [
    //         {
    //             breakpoint: 1199,
    //             settings: {
    //                 slidesToShow: 4,
    //                 slidesToScroll: 4,
    //             }
    //         },
    //         {
    //             breakpoint: 991,
    //             settings: {
    //                 slidesToShow: 3,
    //                 slidesToScroll: 3,
    //             }
    //         },
    //         {
    //             breakpoint: 660,
    //             settings: {
    //                 slidesToShow: 2,
    //                 slidesToScroll: 2,
    //             }
    //         },
    //         {
    //             breakpoint: 450,
    //             settings: {
    //                 slidesToShow: 1,
    //                 slidesToScroll: 1,
    //             }
    //         }
    //     ]
    // });

    var promotionCarousel = jQuery('.js-promotion-carousel');
    if (promotionCarousel !== null) {
        promotionCarousel.owlCarousel({
            lazyLoad: true,
            // loop:true,
            margin: 10,
            nav: true,
            dots: false,
            slideBy: 4,
            autoHeight: true,
            autoplay: false,
            autoplayHoverPause: true,
            navText: ['<div class="slider__arrow slider__arrow--left"></div>', '<div class="slider__arrow slider__arrow--right"></div>'],
            responsive: {
                0: {
                    items: 1,
                    slideBy: 1
                },
                660: {
                    items: 2,
                    slideBy: 2,
                },
                991: {
                    items: 3,
                    slideBy: 3
                },
                1199: {
                    items: 4,
                    slideBy: 4
                }
            }
        });
    }

    // jQuery('.js-promotion-carousel').slick({
    //     infinite: true,
    //     arrows: true,
    //     autoplay: false,
    //     adaptiveHeight: true,
    //     pauseOnHover: true,
    //     slidesToShow: 4,
    //     slidesToScroll: 4,
    //     slide: '.product',
    //     rows: 0,
    //     nextArrow: '<div class="slider__arrow slider__arrow--right"></div>',
    //     prevArrow: '<div class="slider__arrow slider__arrow--left"></div>',
    //     responsive: [
    //         {
    //             breakpoint: 1199,
    //             settings: {
    //                 slidesToShow: 3,
    //                 slidesToScroll: 3,
    //             }
    //         },
    //         {
    //             breakpoint: 991,
    //             settings: {
    //                 slidesToShow: 2,
    //                 slidesToScroll: 2,
    //             }
    //         },
    //         {
    //             breakpoint: 660,
    //             settings: {
    //                 slidesToShow: 1,
    //                 slidesToScroll: 1,
    //             }
    //         }
    //     ]
    // });


    // jQuery('.js-single-arrangement-gallery').slick({
    //     infinite: true,
    //     arrows: true,
    //     autoplay: true,
    //     pauseOnHover: true,
    //     variableWidth: true,
    //     slidesToShow: 4,
    //     slidesToScroll: 1,
    //     slide: '.single-element',
    //     rows: 0,
    //     nextArrow: '<div class="slider__arrow slider__arrow--right"></div>',
    //     prevArrow: '<div class="slider__arrow slider__arrow--left"></div>',
    //     responsive: [
    //         {
    //             breakpoint: 1199,
    //             settings: {
    //                 slidesToShow: 4,
    //                 slidesToScroll: 1,
    //             }
    //         },
    //         {
    //             breakpoint: 991,
    //             settings: {
    //                 slidesToShow: 3,
    //                 slidesToScroll: 1,
    //             }
    //         },
    //         {
    //             breakpoint: 660,
    //             settings: {
    //                 slidesToShow: 2,
    //                 slidesToScroll: 1,
    //             }
    //         },
    //         {
    //             breakpoint: 400,
    //             settings: {
    //                 slidesToShow: 1,
    //                 slidesToScroll: 1,
    //             }
    //         }
    //     ]
    // });

    var projectGallery = jQuery('.js-carousel-gallery');
    if (projectGallery !== null) {
        projectGallery.owlCarousel({
            lazyLoad: true,
            loop:true,
            margin: 10,
            nav: true,
            dots: false,
            autoHeight: true,
            items: 4,
            slideBy: 1,
            autoplay: true,
            autoplayHoverPause: true,
            navText: ['<div class="slider__arrow slider__arrow--left"></div>', '<div class="slider__arrow slider__arrow--right"></div>'],
            responsive: {
                0: {
                    items: 1
                },
                440: {
                    items: 2
                },
                660: {
                    items: 3
                },
                991: {
                    items: 4
                }
            }
        });
    }

    // jQuery('.js-carousel-gallery').slick({
    //     infinite: true,
    //     arrows: true,
    //     autoplay: true,
    //     adaptiveHeight: true,
    //     pauseOnHover: true,
    //     slidesToShow: 4,
    //     slidesToScroll: 1,
    //     slide: '.single-element',
    //     rows: 0,
    //     nextArrow: '<div class="slider__arrow slider__arrow--right"></div>',
    //     prevArrow: '<div class="slider__arrow slider__arrow--left"></div>',
    //     responsive: [
    //         {
    //             breakpoint: 1199,
    //             settings: {
    //                 slidesToShow: 4,
    //                 slidesToScroll: 1,
    //             }
    //         },
    //         {
    //             breakpoint: 991,
    //             settings: {
    //                 slidesToShow: 3,
    //                 slidesToScroll: 1,
    //             }
    //         },
    //         {
    //             breakpoint: 660,
    //             settings: {
    //                 slidesToShow: 2,
    //                 slidesToScroll: 1,
    //             }
    //         },
    //         {
    //             breakpoint: 400,
    //             settings: {
    //                 slidesToShow: 1,
    //                 slidesToScroll: 1,
    //             }
    //         }
    //     ]
    // });

    var otherArrangementGallery = jQuery('.js-other-arrangement-gallery');
    if (otherArrangementGallery !== null) {
        otherArrangementGallery.owlCarousel({
            lazyLoad: true,
            loop:false,
            margin: 10,
            nav: true,
            dots: false,
            items: 3,
            slideBy: 1,
            autoplay: false,
            autoHeight: true,
            autoplayHoverPause: true,
            navText: ['<div class="slider__arrow slider__arrow--left"></div>', '<div class="slider__arrow slider__arrow--right"></div>'],
            responsive: {
                0: {
                    items: 1
                },
                660: {
                    items: 2
                },
                991: {
                    items: 3
                }
            }
        });
    }

    var catalog_carousel = jQuery('.js-catalog-carousel');
    if (catalog_carousel !== null) {
        catalog_carousel.owlCarousel({
            lazyLoad: true,
            loop: false,
            nav: true,
            dots: false,
            items: 1,
            slideBy: 1,
            autoplay: false,
            autoHeight: true,
            navText: ['<div class="slider__arrow slider__arrow--left"></div>', '<div class="slider__arrow slider__arrow--right"></div>'],
        });
    }

    // jQuery('.js-other-arrangement-gallery').slick({
    //     infinite: true,
    //     arrows: true,
    //     autoplay: false,
    //     adaptiveHeight: true,
    //     pauseOnHover: true,
    //     slidesToShow: 3,
    //     slidesToScroll: 1,
    //     slide: '.other-single-arrangement',
    //     rows: 0,
    //     nextArrow: '<div class="slider__arrow slider__arrow--right"></div>',
    //     prevArrow: '<div class="slider__arrow slider__arrow--left"></div>',
    //     responsive: [
    //         {
    //             breakpoint: 1199,
    //             settings: {
    //                 slidesToShow: 3,
    //                 slidesToScroll: 1,
    //             }
    //         },
    //         {
    //             breakpoint: 991,
    //             settings: {
    //                 slidesToShow: 2,
    //                 slidesToScroll: 1,
    //             }
    //         },
    //         {
    //             breakpoint: 660,
    //             settings: {
    //                 slidesToShow: 1,
    //                 slidesToScroll: 1,
    //             }
    //         }
    //     ]
    // });

    var singleProductThumbnailsSlider = jQuery('.js-single-product-thumbnails-slider');
    var singleProductThumbnailsSliderNav = jQuery('.js-single-product-thumbnails-slider-nav');
    var slidesPerPage = 3;
    var syncedSecondary = true;

    singleProductThumbnailsSlider.owlCarousel({
        lazyLoad: true,
        items: 1,
        slideSpeed: 2000,
        nav: false,
        autoplay: false,
        dots: false,
        loop: false,
        responsiveRefreshRate: 200
    }).on('changed.owl.carousel', syncPosition);

    singleProductThumbnailsSliderNav.on('initialized.owl.carousel', function() {
        singleProductThumbnailsSliderNav.find(".owl-item").eq(0).addClass("current");
    }).owlCarousel({
        lazyLoad: true,
        items: slidesPerPage,
        loop: false,
        dots: false,
        nav: true,
        margin: 10,
        smartSpeed: 200,
        slideSpeed: 500,
        slideBy: 1, //alternatively you
        responsiveRefreshRate: 100,
        navText: [
            '<div class="single-product-thumbnails-slider__arrow single-product-thumbnails-slider__arrow--left single-product-thumbnails-slider__arrow--small"></div>',
            '<div class="single-product-thumbnails-slider__arrow single-product-thumbnails-slider__arrow--right single-product-thumbnails-slider__arrow--small"></div>'
        ],
        responsive: {
            0: {
                items: 2
            },
            1199: {
                items: 3
            }
        }
    });

    function syncPosition(el) {
        var current = el.item.index;

        singleProductThumbnailsSliderNav
            .find(".owl-item")
            .removeClass("current")
            .eq(current)
            .addClass("current");
        var onscreen = singleProductThumbnailsSliderNav.find('.owl-item.active').length - 1;
        var start = singleProductThumbnailsSliderNav.find('.owl-item.active').first().index();
        var end = singleProductThumbnailsSliderNav.find('.owl-item.active').last().index();

        if (current > end) {
            singleProductThumbnailsSliderNav.data('owl.carousel').to(current, 100, true);
        }
        if (current < start) {
            singleProductThumbnailsSliderNav.data('owl.carousel').to(current - onscreen, 100, true);
        }
    }

    function syncPosition2(el) {
        if (syncedSecondary) {
            var number = el.item.index;
            singleProductThumbnailsSlider.data('owl.carousel').to(number, 100, true);
        }
    }

    singleProductThumbnailsSliderNav.on("click", ".owl-item", function(e) {
        e.preventDefault();
        var number = jQuery(this).index();
        singleProductThumbnailsSlider.data('owl.carousel').to(number, 300, true);
    });

    // jQuery('.js-single-product-thumbnails-slider').slick({
    //     slidesToShow: 1,
    //     slidesToScroll: 1,
    //     arrows: false,
    //     fade: false,
    //     adaptiveHeight: false,
    //     infinite: false,
    //     useTransform: true,
    //     speed: 400,
    // }).on('setPosition', function (event, slick) {
    //     slick.$slides.css('height', slick.$slideTrack.height() + 'px');
    // });

    // jQuery('.js-single-product-thumbnails-slider-nav')
    //     .on('init', function(event, slick) {
    //         jQuery('.js-single-product-thumbnails-slider-nav .slick-slide.slick-current').addClass('is-active');
    //     })
    //     .slick({
    //         arrows: true,
    //         slidesToShow: 3,
    //         slidesToScroll: 1,
    //         dots: false,
    //         focusOnSelect: false,
    //         infinite: false,
    //         responsive: [
    //             {
    //                 breakpoint: 1199,
    //                 settings: {
    //                     slidesToShow: 2,
    //                     slidesToScroll: 1,
    //                 }
    //             },
    //             {
    //                 breakpoint: 991,
    //                 settings: {
    //                     slidesToShow: 2,
    //                     slidesToScroll: 1,
    //                 }
    //             },
    //             {
    //                 breakpoint: 660,
    //                 settings: {
    //                     slidesToShow: 2,
    //                     slidesToScroll: 1,
    //                 }
    //             }
    //         ],
    //         nextArrow: '<div class="single-product-thumbnails-slider__arrow single-product-thumbnails-slider__arrow--right single-product-thumbnails-slider__arrow--small"></div>',
    //         prevArrow: '<div class="single-product-thumbnails-slider__arrow single-product-thumbnails-slider__arrow--left single-product-thumbnails-slider__arrow--small"></div>',
    //     });

    // jQuery('.js-single-product-thumbnails-slider').on('afterChange', function(event, slick, currentSlide) {
    //     jQuery('.js-single-product-thumbnails-slider-nav').slick('slickGoTo', currentSlide);
    //     var currrentNavSlideElem = '.slider-nav .slick-slide[data-slick-index="' + currentSlide + '"]';
    //     jQuery('.js-single-product-thumbnails-slider-nav .slick-slide.is-active').removeClass('is-active');
    //     jQuery(currrentNavSlideElem).addClass('is-active');
    // });
    //
    // jQuery('.js-single-product-thumbnails-slider-nav').on('click', '.slick-slide', function(event) {
    //     event.preventDefault();
    //     var goToSingleSlide = jQuery(this).data('slick-index');
    //
    //     jQuery('.js-single-product-thumbnails-slider').slick('slickGoTo', goToSingleSlide);
    // });

    // var servitor_online_modal = jQuery('.js-servitor-online');
    // if (servitor_online_modal.length > 0) {
    //     if (!sessionStorage.getItem('shown-servitor-modal')){
    //         sessionStorage.setItem('shown-servitor-modal', 'true');
    //         servitor_online_modal.nt_servitor_online_modal();
    //     }
    // } else {
    //     geolocation();
    // }

    var stickyDiv = "sticky";
    var yourHeader = jQuery('.header').height();

    var yourNavigation = jQuery(".header__navigation");
    if (window.innerWidth <= 870) {
        yourNavigation = jQuery(".header");
    }

    jQuery(window).scroll(function() {
        if( jQuery(this).scrollTop() > yourHeader ) {
            if (window.innerWidth <= 870) {
                jQuery('body').css({ 'margin-top' : yourHeader + 'px' });
            } else {
                jQuery('body').css({ 'margin-top' : yourNavigation.height() + 'px' });
            }
            yourNavigation.addClass(stickyDiv);
        } else {
            yourNavigation.removeClass(stickyDiv);
            jQuery('body').css({ 'margin-top' : 0 });
        }
    });

    lightbox.option({
        'resizeDuration': 200,
        'wrapAround': true,
        'albumLabel': 'Zdjęcie %1 z %2'
    });

    let ps_lightbox = new PhotoSwipeLightbox({
        gallery: '.js-single-product-thumbnails-slider',
        children: 'a',
        pswpModule: () => import('photoswipe/dist/umd/photoswipe.umd.min')
    });
    ps_lightbox.init();

    jQuery( 'input#min_price, input#max_price' ).hide();
    jQuery( '.price_slider, .price_label' ).show();

    var min_price = jQuery( '.price_slider_amount #min_price' ).data( 'min' ),
        max_price = jQuery( '.price_slider_amount #max_price' ).data( 'max' ),
        current_min_price = jQuery( '.price_slider_amount #min_price' ).val(),
        current_max_price = jQuery( '.price_slider_amount #max_price' ).val();

    // jQuery( '.price_slider:not(.ui-slider)' ).slider({
    //     range: true,
    //     animate: true,
    //     min: min_price,
    //     max: max_price,
    //     values: [ current_min_price, current_max_price ],
    //     step: 1,
    //     create: function() {
    //
    //         jQuery( '.price_slider_amount #min_price' ).val( current_min_price );
    //         jQuery( '.price_slider_amount #max_price' ).val( current_max_price );
    //
    //         jQuery( '.price_slider_amount span.from' ).html(current_min_price + ' zł');
    //         jQuery( '.price_slider_amount span.to' ).html(current_max_price + ' zł');
    //
    //         jQuery( document.body ).trigger( 'price_slider_create', [ current_min_price, current_max_price ] );
    //         jQuery( document.body ).trigger( 'price_slider_slide', [ current_min_price, current_max_price ] );
    //     },
    //     slide: function( event, ui ) {
    //
    //         jQuery( 'input#min_price' ).val( ui.values[0] );
    //         jQuery( 'input#max_price' ).val( ui.values[1] );
    //
    //         jQuery( '.price_slider_amount span.from' ).html(ui.values[0] + ' zł');
    //         jQuery( '.price_slider_amount span.to' ).html(ui.values[1] + ' zł');
    //
    //         jQuery( document.body ).trigger( 'price_slider_slide', [ ui.values[0], ui.values[1] ] );
    //     },
    //     change: function( event, ui ) {
    //
    //         console.log(ui.values);
    //
    //         jQuery( '.price_slider_amount span.from' ).html(ui.values[0] + ' zł');
    //         jQuery( '.price_slider_amount span.to' ).html(ui.values[1] + ' zł');
    //
    //         jQuery( document.body ).trigger( 'price_slider_updated', [ ui.values[0], ui.values[1] ]);
    //     }
    // });

    if (jQuery('.js-tag-select').length > 0) {
        jQuery('.js-tag-select').tagSelect();
    }

    jQuery('.js-attribute-filter').filterAttributes();
    jQuery('.js-hidden-filter-options').hiddenFilterOptions();
    jQuery('.js-filter-box').filterBox();

    var $fv = jQuery('.js-fv-checkbox');
    if ($fv.length > 0) {

        console.log($fv);

        var $fields_to_hide = $fv.closest('.box__content').find('.js-fvat-to-hide');
        var $fields_to_show = $fv.closest('.box__content').find('.js-fvat-to-show');

        console.log($fv);

        jQuery.each($fields_to_show, function(){
            jQuery(this).hide();
        });

        $fv.on('change', function() {
            console.log('change');
            if (jQuery(this).is(':checked')) {
                jQuery.each($fields_to_show, function(){
                    jQuery(this).show();
                });
                jQuery.each($fields_to_hide, function(){
                    jQuery(this).hide();
                });
            } else {
                jQuery.each($fields_to_show, function(){
                    jQuery(this).hide();
                });
                jQuery.each($fields_to_hide, function(){
                    jQuery(this).show();
                });
            }
        });
    }
    jQuery('.js-arrangement-data').arrangementData();
});

jQuery(document).keydown(function(e) {
    var $paper = jQuery('.js-paper');
    if ($paper.length > 0) {
        switch (e.keyCode) {
            case 37:
                $paper.turn('previous');
                break;
            case 39:
                $paper.turn('next');
                break;
        }
    }
});