import jQuery from 'jquery';

(function($){
    $.fn.tagSelect = function(){
        var $el = null;
        var url = '';

        var init = function(){
            $el.on('change', redirect_to_tag_page);
            url = $el.attr('data-tag-url');
        };

        var redirect_to_tag_page = function() {
            var selected_tag = jQuery(this).children('option:selected').val();
            window.location.href = url + selected_tag;
        };

        if(this.length > 0){
            if(this.length > 1){
                this.each(function(){
                    jQuery(this).tagSelect();
                })
            } else {
                $el = this;
                init();
            }
        }
    }
})(jQuery);