import jQuery from 'jquery';
import * as cookie from './cookie';

const geolocation = () => {
    let selected = cookie.get('nt_location_selected');
    if (selected === '') {
        if (navigator.geolocation) {
            cookie.set('nt_location', 72, 30);
            cookie.set('nt_location_selected', true);
            // navigator.geolocation.getCurrentPosition(geolocation_success, geolocation_error, { timeout: 10000 });
        } else {
            cookie.set('nt_location_selected', true);
        }
    }
};

const geolocation_success = position => {

    jQuery(document).ready(
        jQuery.ajax({
            url: '/wp-admin/admin-ajax.php',
            type: 'post',
            data: {
                position: {
                    lat: position.coords.latitude,
                    lng: position.coords.longitude
                },
                action: 'geolocation'
            },
            dataType: 'json',
            success: function(response) {
                // cookie.set('nt_location', response.location, 30);
                cookie.set('nt_location', 72, 30);
                cookie.set('nt_location_selected', true);

                jQuery('.js-modal').nt_modal();
            },
            error: function(error) {
                // console.log(error);
            }
        })
    );
};

const geolocation_error = error => {
    // console.log(error);
    cookie.set('nt_location_selected', true);
};

export default geolocation;