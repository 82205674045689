import jQuery from 'jquery';
import geolocation from "./geolocation";

(function ($) {
    $.fn.nt_modal = function () {
        var $el = null;
        var $close = null;

        var init = function () {
            $close = $el.find('[data-element="close"]');
            $close.on('click', close_modal);
            $('body').addClass('mask');
            $el.css({ 'display' : 'block' });
        };

        var close_modal = function(e) {
            e.preventDefault();

            $el.css({ 'display' : 'none' });
            $('body').removeClass('mask');
        };

        if (this.length > 0) {
            if (this.length > 1) {
                this.each(() => {
                    $(this).nt_modal();
                })
            } else {
                $el = this;
                init();
            }
        }
    }
})(jQuery);

(function ($) {
    $.fn.nt_servitor_online_modal = function () {
        var $el = null;
        var $close = null;

        var init = function () {
            $close = $el.find('[data-element="close"]');
            $close.on('click', close_modal);
            $('body').addClass('mask');
            $el.css({ 'display' : 'block' });


        };

        var close_modal = function(e) {
            e.preventDefault();

            $el.css({ 'display' : 'none' });
            $('body').removeClass('mask');

            geolocation();
        };

        if (this.length > 0) {
            if (this.length > 1) {
                this.each(() => {
                    $(this).nt_servitor_online_modal();
                })
            } else {
                $el = this;
                init();
            }
        }
    }
})(jQuery);