import jQuery from 'jquery';

(function ($) {
    $.fn.arrangementProductList = function() {
        var $el = null;
        var $products = null;
        var $total_price = null;
        var $add_to_cart = null;

        var init = function() {
            $products = $el.find('[data-product]');
            $total_price = $el.find('[data-element="total-price"]');
            $add_to_cart = $el.find('[data-element="add-to-cart"]');

            $products.on('change', '[data-element="quantity"]', update_product_list);
            $products.on('click', '[data-product-remove]', remove_from_product_list);
            $add_to_cart.on('click', update_cart);
        };

        var update_product_list = function() {
            var total_price_amount = 0;
            var subtotal_price_amount = 0;
            $products = $el.find('[data-product]');
            $products.each(function(key, value) {
                var quantity = jQuery(value).find('[data-element="quantity"]').val();
                jQuery(value).find('[data-element="quantity"]').val(jQuery(value).find('[data-element="quantity"]').val());

                var price = jQuery(value).attr('data-product-price');
                var $subtotal = jQuery(value).find('[data-element="subtotal"]');

                subtotal_price_amount = quantity*price;
                $subtotal.text(subtotal_price_amount.toFixed(2) + ' zł');

                total_price_amount += quantity*price;

                $el.find('[data-element="total-price"]').text(total_price_amount.toFixed(2) + ' zł');
            });
        };

        var remove_from_product_list = function(e) {
            e.preventDefault();
            $(this).parent().parent().remove();
            update_product_list();
        };

        var update_cart = function(e) {
            e.preventDefault();
            var array = [];
            $products = $el.find('[data-product]');
            $products.each(function(key, value) {
                array.push(jQuery(value).attr('data-product') + ':' + jQuery(value).find('[data-element="quantity"]').val());
            });

            var data = {
                products: array,
                action: 'add_to_cart',
            };
            if (array.length > 0) {
                $.ajax({
                    type: 'POST',
                    url: '/wp-admin/admin-ajax.php',
                    data: data,
                    dataType: 'json',
                    success: function(response) {
                        if (response.status === 'success') {
                            window.location.href = response.cart;
                        }
                    }
                });
            }
        };

        if (this.length > 0) {
            if (this.length > 1) {
                this.each(function() {
                    $(this).arrangementProductList();
                });
            } else {
                $el = this;
                init();
            }
        }
    };
})(jQuery);