import jQuery from 'jquery';

(function ($) {
    $.fn.categoryTabs = function () {
        var $el = null;
        var $single_tab = null;
        var $filter_element = null;
        var callback = null;

        var init = function () {
            // console.log($el);

            if (typeof $el.attr('data-filters') !== typeof undefined && $el.attr('data-filters') !== false) {
                $filter_element = $el;
                $filter_element.find('[data-element]').show();
                $filter_element.find('[data-element]').addClass('active');
                // console.log($filter_element);
            }

            $single_tab = $el.find('[data-element]');

            $single_tab.each(function() {
                var $tmp = $(this);
                if ($tmp.hasClass('special-active')) {
                    setTimeout(function() {
                        $tmp.removeClass('special-active');
                    }, 2000);
                }

                var subcategory = $(this).find('.subcategory');
                console.log(subcategory);
                if (subcategory.length > 0) {
                    var active_element = subcategory.find('.active');
                    console.log(active_element);
                    if (active_element.length > 0) {
                        console.log('dodano');
                        active_element.closest('[data-element]').addClass('active');
                    }
                }
            });
            $single_tab.find('[data-click]').on('click', onSingleTabClick);

            callback = $el.attr('data-callback');
        };

        var onSingleTabClick = function(e) {
            e.preventDefault();

            var $current_element = $(this).closest('[data-element]');
            if ($current_element.hasClass('active')) {
                $current_element.removeClass('active');
                // $current_element.find('[data-content]').hide('slow');
            } else if (!$current_element.hasClass('active')) {
                $current_element.addClass('active');
                // $current_element.find('[data-content]').show('slow');
            }

            if (typeof callback !== 'undefined') {
                window[callback]();
            }
        };

        if (this.length > 0) {
            if (this.length > 1) {
                this.each(function () {
                    $(this).categoryTabs();
                })
            } else {
                $el = this;
                init();
            }
        }
    }
})(jQuery);