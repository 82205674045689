import jQuery from 'jquery';

(function ($) {
    $.fn.filters = function () {
        var $el = null;

        var init = function () {
            $el.on('change, click', '[data-element="checkbox"]', function() {
                var str = $el.find('[data-element="checkbox"]:checked').map(function () {
                    return $(this).val();
                }).get().join(',');

                $el.parent().find('input[type="hidden"]').val(str);
            });
        };

        if (this.length > 0) {
            if (this.length > 1) {
                this.each(function () {
                    $(this).filters();
                })
            } else {
                $el = this;
                init();
            }
        }
    }
})(jQuery);